/*
 * @Auther: 罗耀祖
 * @Date: 2021-07-14 20:07:58
 * @LastEditors: 罗耀祖
 * @LastEditTime: 2021-07-14 20:14:48
 * @name: 
 * @Description: 
 * @FilePath: \datav\datav\src\router\index.js
 * @Author: DESKTOP-1QU67QM
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
